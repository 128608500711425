<template>
    <PublicLayoutTwo
        title="The natural union between talents & organizations"
        description="We help talents & companies to find a match for their needs for a best work environment and morale booter, improving collaboration success, productivity and career progression."
        :is-home-page="true">
        <section class="relative md:bg-home-world-map bg-no-repeat bg-bottom">
            <div
                class="py-14 md:pb-20 md:pt-40 px-6 md:px-10 2xl:px-40 3xl:px-[270px] bg-gradient-to-b from-primary-content h-full">
                <div class="flex flex-col xl:flex-row gap-12 lg:gap-24">
                    <div class="flex flex-col gap-8 lg:gap-14 xl:w-1/2">
                        <div class="text-4xl leading-10 lg:text-[40px] lg:leading-[60px] font-light">
                            {{
                                $t(
                                    "We're dedicated to creating environments where brilliant minds connect, build, and launch exceptional products.",
                                )
                            }}
                        </div>
                        <div class="flex flex-wrap gap-2 text-sm xl:w-1/2 w-full">
                            <span
                                v-for="domain in domains"
                                :key="domain"
                                class="px-4 py-3 border border-base-content rounded-full">
                                {{ domain }}
                            </span>
                        </div>
                    </div>
                    <div class="flex flex-col lg:flex-row xl:flex-col gap-14 xl:w-1/2 w-full">
                        <div class="flex flex-col gap-4 text-sm">
                            <span class="uppercase">{{ $t('Empowering teams around the world') }}</span>
                            <div class="grid grid-cols-1 lg:grid-cols-2">
                                <div
                                    v-for="(team, index) in teamsEmpowered"
                                    :key="index"
                                    class="px-4 py-1 border border-base-200 min-w-72 min-h-36 col-span-1">
                                    <component :is="team" :key="index" class="m-auto h-full"> </component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-wrap gap-2 mt-14 mb-20 w-full xl:w-1/2">
                    <div v-for="statistic in statistics" :key="statistic.title" class="flex flex-col gap-2 max-w-56">
                        <span class="text-5xl">{{ statistic.title }}</span>
                        <span class="font-light">{{ statistic.description }}</span>
                    </div>
                </div>
            </div>
        </section>
        <Testimonials />
        <TopTierTech />
        <AdvantagesSection v-bind="clientAdvantages" />
        <TalentsSharingProcess v-bind="talentFindingProcess" />
    </PublicLayoutTwo>
</template>

<script setup lang="ts">
import AdvantagesSection from './Partials/Home/AdvantagesSection.vue';
import TalentsSharingProcess from './Partials/Home/TalentsSharingProcess.vue';
import Testimonials from './Partials/Home/Testimonials.vue';
import TopTierTech from './Partials/Home/TopTierTech.vue';
import PublicLayoutTwo from '@/Layouts/PublicLayoutTwo.vue';
import {
    AcompanyColorIcon,
    AdjustmentIcon,
    BadgeCheckIcon,
    BuzzooleColorIcon,
    CoderblockColorIcon,
    DocumentIcon,
    DollarCircleIcon,
    LightningIcon,
    SCMColorIcon,
    SmileyIcon,
    StrookaColorIcon,
} from '@/images/icons';

const talentFindingProcess = {
    processName: 'Talent finding process',
    title: 'Revolutionize talent acquisition with our global search platform.',
    description: 'Effortlessly identify and connect with world-class talents.',
    steps: [
        {
            position: 'Send us a request',
            description:
                'Share your project details and objectives through our easy-to-use request submission. <a href="/create-project" class="text-primary underline">Here</a>',
        },
        {
            position: 'We run our talent matcher',
            description:
                "Our Cleos AI assistant, backed by our HR team, efficiently processes your talent search, giving you the confidence that you'll receive top matches.",
        },
        {
            position: 'Get the talents you need',
            description:
                'You get best talents profiles in your inbox, carefully curated based on your goals and needs.',
        },
    ],
};

const clientAdvantages = {
    titleOne: 'For businesses aiming to scale with precision...',
    titleTwo: 'the Scaling Platform offers access to talent that drives success.',
    titleThree: 'learn about our Scaling Advantages',
    cta: {
        primaryText: 'I’m ready to start',
        to: 'projects.create',
    },
    advantages: [
        {
            icon: DollarCircleIcon,
            title: 'Cost effective',
            description:
                'Access top talent from the global market while maintaining full control over your investments.',
            subDescription: [
                {
                    title: 'VS other solutions on market:',
                    description: '',
                },
                {
                    title: 'Recruitment agencies',
                    description: "usually take 20-30% of the talent's annual salary for sourcing.",
                },
                {
                    title: 'Professional services firms',
                    description: '70-300% markup on monthly salaries, with all fees to be paid one month upfront.',
                },
                {
                    title: 'Freelance platforms',
                    description:
                        " charge fees up to 30-70% of the freelancer's rate, with a full deposit to be paid upfront.",
                },
            ],
        },
        {
            icon: LightningIcon,
            title: 'Lightning fast',
            description: 'Our system manages clients <> talent connection in a fast-paced and controlled environment.',
            subDescription: [
                {
                    title: 'VS other solutions on market:',
                    description: '',
                },
                {
                    title: 'Recruitment agencies',
                    description: 'hiring is tedious and takes up to 3 months.',
                },
                {
                    title: 'Professional services firms',
                    description: 'quality is a hit-and-miss since companies do not curate professionals.',
                },
                {
                    title: 'Freelance platforms',
                    description:
                        'productivity is typically low as most freelancers work on multiple projects simultaneously.',
                },
            ],
        },
        {
            icon: SmileyIcon,
            title: 'Build strong partnerships',
            description:
                'Our platform fosters collaboration and networking opportunities. Connecting companies and talent from around the world creates a thriving ecosystem where long-term partnerships can flourish. Regular interactions shared project successes, and mutual trust contributes to building strong relationships that can lead to repeat business and referrals.',
        },
        {
            icon: AdjustmentIcon,
            title: 'Total ownership',
            description:
                'Our platform provides complete control over talent management and project execution. We are facilitators, connecting parties and streamlining processes without compromising ownership or control.',
        },
        {
            icon: DocumentIcon,
            title: 'Legal and contractual support',
            description:
                'Our platform offers standardized agreements that outline the terms and conditions for talent sharing, intellectual property rights, payment terms, and dispute resolution. This not only simplifies the legal process but also saves you valuable time and resources, reducing the time and cost associated with drafting and negotiating custom contracts.',
        },
        {
            icon: BadgeCheckIcon,
            title: 'Build your reputation',
            description:
                'By highlighting your ability to access expert talent on demand, our scaling platform positions your organization as a trusted partner for businesses seeking specialized skills. This builds your reputation as an innovative, solution-focused provider, attracting new partners who value high-quality talent and positive feedback from previous engagements.',
        },
    ],
};

const domains = [
    'Interface Design',
    'Development',
    'Strategy',
    'Brand',
    'Support',
    'User experience design',
    'Journey optimization',
];

const statistics = [
    {
        title: '10+',
        description: 'Countries listed across all professionals',
    },
    {
        title: '55+',
        description: 'Technologies & skills available',
    },
    {
        title: '10 years',
        description: 'in tech business creating innovative solutions',
    },
];

const teamsEmpowered = [StrookaColorIcon, BuzzooleColorIcon, CoderblockColorIcon, AcompanyColorIcon, SCMColorIcon];
</script>
